const RemittancesListCtrl = ['$scope', 'Alert', '$filter', 'Bank', 'UserInfo', 'filtersUtils', 'Task', 'REMITTANCE_RULE',
    function ($scope, Alert, $filter, Bank, UserInfo, filtersUtils, Task, REMITTANCE_RULE) {
        /***********************************************************************************************************
         * Controller Attributes
         ***********************************************************************************************************/
        var self = this;
        var $translate = $filter('translate');

        $scope.BANKS_FACTORING = require('../../templates/remittances-list/modals/factoring.html')

        /***********************************************************************************************************
         * Scope Attributes
         ***********************************************************************************************************/
        $scope.itemsToDownload = [];
        $scope.remittanceRules = REMITTANCE_RULE;
        $scope.primaryKey = 'iid';

        $scope.factoring={};

        $scope.draggableFields = {
            "id__in": {
                "label": $translate('POWR.ID')
            }
        };

        $scope.ordering = {
            "id": $translate('BANKS.NO_REMITTANCE'),
            "creation_date": $translate('BANKS.CREATION_DATE'),
            "date": $translate('BANKS.DATE')
        };

        $scope.searchFilters = {
            'remittance_id': $translate('BANKS.NO_REMITTANCE')
        };

        $scope.filters = {
            remittance_id: ""
        };

        $scope.type = [
            {'label': $translate('BANKS.NORMAL'), 'value': 'False'},
            {'label': $translate('BANKS.REFUND_REMIT'), 'value': 'True'},
            {'label': $translate('BANKS.NOT_DEBITED'), 'value': 'NDB'}
        ];

        $scope.searchBy = Object.keys($scope.searchFilters)[0];
        $scope.searchByLabel = $scope.searchFilters[$scope.searchBy];

        $scope.date_filters = {
            product_date_range: null,
            date_range_from: null,
            date_range_to: null
        };

        $scope.filters_by_date = [
            {value: "creation_", description: $translate('BANKS.Creation date range')},
            {value: "register_", description: $translate('BANKS.Register date')},
            {value: "", description: $translate('BANKS.VALUE_DATE')}
        ];

        /***********************************************************************************************************
         * Scope Watchers
         ***********************************************************************************************************/
        $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
            $scope.counter = selectedCounterChange;
        });

        $scope.$watch('searchQuery', function (newVal, oldVal) {
            $scope.filters[$scope.searchBy] = newVal;
        });

        $scope.$watch('bank', (newVal, oldVal) => {
            if(newVal){
                $scope.filters.bank = newVal.id;
            }
            else{
                $scope.filters.bank = null;
            }
        })

        $scope.$watchCollection('date_filters', function (newVal, oldVal) {
            var sel_option;
            if (oldVal.product_date_range !== null) {
                sel_option = oldVal.product_date_range.value;
                if (oldVal.date_range_from !== null) {
                    $scope.filters[sel_option + "date_from"] = null;
                }
                if (oldVal.date_range_to !== null) {
                    $scope.filters[sel_option + "date_to"] = null;
                }
            }
            var from_date;
            var to_date;
            if (newVal.product_date_range !== null) {
                sel_option = newVal.product_date_range.value;
                if (newVal.date_range_from !== null) {
                    from_date = newVal.date_range_from;
                    $scope.filters[sel_option + "date_from"] = from_date;
                }
                if (newVal.date_range_to !== null) {
                    to_date = newVal.date_range_to;
                    $scope.filters[sel_option + "date_to"] = to_date;
                }
            }
        });

        /***********************************************************************************************************
         * Controller Methods
         ***********************************************************************************************************/
        this.getJSONParams = function (elements) {
            var params = {};
            params.ids = filtersUtils.getItemsToDownloadToInt(elements);
            return params;
        };

        /***********************************************************************************************************
         * Scope Methods
         ***********************************************************************************************************/
        $scope.downloadRemittanceReceipts = function () {
            if ($scope.counter != 0) {
                var task = new Task();
                task.type = 'RCSV';
                task.params = self.getJSONParams($scope.itemsToDownload);
                task.start().then(function (data, status, headers, config) {
                    Alert.createAlert('success', $translate('BANKS.Bulk download CSV'), true);
                    $scope.backgroundTasks.push(data.data.id);
                }, function (data, status, headers, config) {
                    Alert.createAlert('danger', $translate('BANKS.Bulk download generation Task error'), true);
                });
            }
        };

        $scope.canCreateRemittance = function () {
            return UserInfo.hasPerm('banks.can_remit');
        };

        $scope.canDownloadRemittance = function () {
            return UserInfo.hasPerm('banks.retrieve_remittance');
        };

        $scope.canLockUnlockRemittances = function () {
            return UserInfo.hasPerm('banks.lock_unlock_remittance');
        };

        $scope.downloadFactoring = function () {
            var params = '';

            if($scope.factoring.invoiced_date)
                params += '&invoiced_date=' + $scope.factoring.invoiced_date;

            if($scope.factoring.payment_deadline_days)
                params += '&payment_deadline_days=' + $scope.factoring.payment_deadline_days;

            window.open('/remittances/download/factoring/' + $scope.item.id + '?' + params, "_blank");

        };
        /***********************************************************************************************************
         * Controller Initialization
         ***********************************************************************************************************/
    }
]

export { RemittancesListCtrl }
