const ReceiptsListCtrl = ['$scope', '$filter', 'UserInfo', 'filtersUtils', 'Task', 'Alert',
    function ($scope, $filter, UserInfo, filtersUtils, Task, Alert) {
        /***********************************************************************************************************
        * Controller Attributes
        ***********************************************************************************************************/
        var self = this;
        var $translate = $filter('translate');
        
        $scope.backgroundTasks = [];

        $scope.BANKS_RECEIPT_LIST_RESULT = require('../../templates/receipts-list-result.html')

        /***********************************************************************************************************
        * Scope Attributes
        ***********************************************************************************************************/
        $scope.itemsToDownload = [];

        $scope.searchFilters = {
            'id': $translate('BANKS.RECEIPT_NUMBER'),
            'invoice_id': $translate('BANKS.NO_INVOICE'),
            'remittance_id': $translate('BANKS.NO_REMITTANCE'),
            'account': $translate('BANKS.ACCOUNT'),
            'sepa': $translate('BANKS.SEPA'),
            'search': $translate('BANKS.ALL')
        };

        $scope.draggableFields = {
            "id__in": {
                "label": $translate('BANKS.RECEIPT_NUMBER'),
                "pattern": "^\\w+$"
            },
            "invoice__idn__in":{
                "label": $translate('INVOICES.NO_INVOICE'),
                "pattern": "^\\w+$"
            },
            "remittance__id__in":{
                "label": $translate('BANKS.NO_REMITTANCE'),
                "pattern": "^\\w+$"
            }
        };

        $scope.statuses = [
            {code: 'PAI', label: $translate('BANKS.PAID')},
            {code: 'RTN', label: $translate('BANKS.RETURNED')},
            {code: 'RJC', label: $translate('BANKS.REJECTED')},
            {code: 'MAN', label: $translate('BANKS.MANUAL')}
        ];

        $scope.types = [
            {code: 'NOR', label: $translate('BANKS.NORMAL')},
            {code: 'RCV', label: $translate('BANKS.RECOVER')}
        ];

        $scope.searchBy = Object.keys($scope.searchFilters)[0];
        $scope.searchByLabel = $scope.searchFilters[$scope.searchBy];

        $scope.filters = {
            search:"",
            id: "",
            invoice_id: "",
            remittance_id: "",
            status: "",
            total_min: "",
            total_max: "",
            type: ""
        };

        $scope.date_filters = {
            receipt_date_range: null,
            date_range_from: null,
            date_range_to: null
        };

        $scope.filters_by_date = [
            { value: "receipt", description: $translate('BANKS.CREATION_DATE') },
            { value: "unpaid", description: $translate('BANKS.UNPAID_DATE') }
        ];

        $scope.ordering = {
            "total": $translate('BANKS.TOTAL'),
            "date": $translate('BANKS.DATE')
        };

        /***********************************************************************************************************
        * Scope Watchers
        ***********************************************************************************************************/
        $scope.$watchCollection('date_filters', function(newVal, oldVal){
            if (oldVal.receipt_date_range !== null) {
                var sel_option = oldVal.receipt_date_range.value;
                if (oldVal.date_range_from !== null) {
                    $scope.filters[sel_option + "_date_from"] = null;
                }
                if (oldVal.date_range_to !== null) {
                    $scope.filters[sel_option + "_date_to"] = null;
                }
            }
            if (newVal.receipt_date_range !== null) {
                var sel_option = newVal.receipt_date_range.value;
                if (newVal.date_range_from !== null) {
                    var from_date = newVal.date_range_from;
                    $scope.filters[sel_option + "_date_from"] = from_date;
                }
                if (newVal.date_range_to !== null) {
                    var to_date = newVal.date_range_to;
                    $scope.filters[sel_option + "_date_to"] = to_date;
                }
            }
        });

        $scope.$watch('searchQuery', function (newVal, oldVal) {
            $scope.filters[$scope.searchBy] = newVal;
        });

        /***********************************************************************************************************
        * Controller Methods
        ***********************************************************************************************************/
        this.getJSONParams = function (elements) {
            var params = {};
            params.ids = filtersUtils.getItemsToDownloadToInt(elements);
            params.receipts = true;
            return params;
        };

        /***********************************************************************************************************
        * Scope Methods
        ***********************************************************************************************************/
        $scope.getStyles = function(result){
            var styles = '';
            if(result.status == 'PAI')
                styles += ' '+'success';
            if(result.status == 'RTN')
                styles += ' '+'danger';
            if(result.status == 'RJC')
                styles += ' '+'danger';
            return styles;
        };

        $scope.setSearchBy = function (val) {
            $scope.filters[$scope.searchBy] = '';
            $scope.searchBy = val;
            $scope.searchByLabel = $scope.searchFilters[val];
            $scope.filters[val] = $scope.searchQuery;
        };

        $scope.canDownloadReceipts = function () {
            return UserInfo.hasPerm('banks.list_receipts');
        };

        $scope.downloadReceipts = function () {
            if ($scope.counter != 0) {
                var task = new Task();
                task.type = 'RCSV';
                task.params = self.getJSONParams($scope.itemsToDownload);
                task.start().then(function (data, status, headers, config) {
                    Alert.createAlert('success', $translate('BANKS.Bulk download CSV'), true);
                    $scope.backgroundTasks.push(data.data.id);
                }, function (data, status, headers, config) {
                    Alert.createAlert('danger', $translate('BANKS.Bulk download generation Task error'), true);
                });
            }
        };

    }
]

export { ReceiptsListCtrl }
